import { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ContentFeed.scss';
import { getString } from '../../../helpers/styles';
import Dot from '../../../shared/assets/icons/redesign/dot.svg';
import { srcSet, useDimensions } from '../../../helpers/image';
import { getAdvertisementData } from '../../../helpers/metadata';

export default function ContentFeed({ title, list, highlightFirstItem }) {
  const contentPrimaryImg = useRef();
  const contentPrimaryImgDimensions = useDimensions(contentPrimaryImg);
  const contentSecondaryImg = useRef();
  const contentSecondaryImgDimensions = useDimensions(contentSecondaryImg);
  const channelImg = useRef();
  const channelImgDimensions = useDimensions(channelImg);

  return (
    <div className="contentFeed">
      {title && <h2 className="contentFeed-title">{title}</h2>}

      <ul
        className={getString([
          {
            value: 'contentFeed-list',
            condition: true
          },
          {
            value: 'contentFeed-highlight-first-item',
            condition: highlightFirstItem
          }
        ])}
      >
        {list
          .map(
            ({ id, imageUrl, author, uri, title, parent, metadata }, idx) => {
              const [adName] = getAdvertisementData(metadata) || [];

              return (
                <li key={id}>
                  <a
                    href={`/${uri}`}
                    className="contentFeed-list-item cursor-pointer"
                  >
                    {idx === 0 ? (
                      <div
                        className="contentFeed-list-item-primary-img"
                        ref={contentPrimaryImg}
                      >
                        {contentPrimaryImgDimensions && (
                          <LazyLoadImage
                            alt="content-image"
                            srcSet={srcSet(
                              imageUrl,
                              contentPrimaryImgDimensions
                            )}
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        className="contentFeed-list-item-secondary-img"
                        ref={contentSecondaryImg}
                      >
                        {contentSecondaryImgDimensions && (
                          <LazyLoadImage
                            alt="content-image"
                            srcSet={srcSet(
                              imageUrl,
                              contentSecondaryImgDimensions
                            )}
                          />
                        )}
                      </div>
                    )}

                    <div>
                      <div className="contentFeed-list-item-info">
                        {!adName && (
                          <div
                            className="contentFeed-list-item-channel-logo"
                            ref={channelImg}
                          >
                            {channelImgDimensions && (
                              <LazyLoadImage
                                srcSet={srcSet(
                                  author.imageUrl,
                                  channelImgDimensions
                                )}
                                alt="content-channel-logo"
                              />
                            )}
                          </div>
                        )}

                        <p className="contentFeed-list-item-channel-name">
                          {adName || author.displayName}
                        </p>

                        {parent?.title && (
                          <>
                            <Dot className="contentFeed-list-item-dot-icon" />
                            <p className="contentFeed-list-item-category">
                              {parent.title}
                            </p>
                          </>
                        )}
                      </div>

                      <h4 className="contentFeed-list-item-title">{title}</h4>
                    </div>
                  </a>
                </li>
              );
            }
          )}
      </ul>
    </div>
  );
}
