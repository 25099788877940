import { useState, useEffect } from 'react';

export function src(imageUrl, width, height) {
  if (!imageUrl) return;

  return imageUrl.replace('/w/', `/${width}/`).replace('/h/', `/${height}/`);
}

export function srcSet(imageUrl, { width, height }) {
  if (!imageUrl) return;

  return `
    ${src(imageUrl, width, height)} 1x,
    ${src(imageUrl, 2 * width, 2 * height)} 2x
  `;
}

export function useDimensions(ref) {
  useEffect(() => {
    if (
      !ref.current ||
      !ref.current.scrollWidth ||
      !ref.current.scrollHeight ||
      dimensions
    )
      return;

    setDimensions({
      width: ref.current.scrollWidth,
      height: ref.current.scrollHeight
    });
  }, [ref.current]);

  const [dimensions, setDimensions] = useState();

  return dimensions;
}

export function isImage(filename) {
  return filename?.match(/\.(jpg|jpeg|png|gif|webp)$/i);
}
