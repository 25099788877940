export function getAdvertisementData(metadata) {
  if (!metadata) return;

  const name = metadata.match(/advertorialName: (.*)/);
  const url = metadata.match(/advertorialUrl: (.*)/);

  if (name && url) return [name[1], url[1]];
}

export function getContentReference(metadata) {
  const referenceData = metadata && metadata.match(/\[.+\)/);

  return (
    referenceData &&
    referenceData[0]
      .split(', ')
      .map((item) => {
        const data = item.match(/\[(.+?)]\((.+?)\)/);

        if (data)
          return {
            title: data[1],
            link: data[2]
          };
      })
      .filter(Boolean)
  );
}
